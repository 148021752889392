import React from "react";
import Svg_CHECK_MARK from "../../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_01.svg";
import Svg_CHECK_MARK_MOB from "../../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_06.svg";
import PG_FFC_SECTION_FOUR_CONTENT from "./index.content";

const TipsList = () => (
  <ul>
    {PG_FFC_SECTION_FOUR_CONTENT.tipsList.map(
      ({ beforeBoldText, boldText, afterBoldText }, i) => (
        <li key={i}>
          <picture>
            <source media="(min-width: 768px)" srcSet={Svg_CHECK_MARK} />
            <source media="(max-width: 767px)" srcSet={Svg_CHECK_MARK_MOB} />
            <img src={Svg_CHECK_MARK} alt="" loading="lazy" />
          </picture>
          <p>
            {beforeBoldText}
            <span>{boldText}</span>
            {afterBoldText}
          </p>
        </li>
      )
    )}
  </ul>
);

export default TipsList;
