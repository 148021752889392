import React from "react";
import SVG_LAMP from "../../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_04.svg";
import SVG_LAMP_MOB from "../../../assets/images/vector/pages/fba-fee-calculator/SectionFour/fbaFeeCalculator-s4_05.svg";
import TipsList from "./TipsList";
import Questions from "./Questions";
import "./index.scss";

const SectionFour = () => {
  return (
    <section className="PgFFC-SectionFour" id="secFour">
      <div className="PgFFC-SectionFour__container">
        <picture>
          <source media="(min-width: 768px)" srcSet={SVG_LAMP} />
          <source media="(max-width: 767px)" srcSet={SVG_LAMP_MOB} />
          <img src={SVG_LAMP} alt="" loading="lazy" />
        </picture>
        <h2 className="PgFFC__title PgFFC-SectionFour__mainTitle">
          Tips to Lower Your FBA Fees
        </h2>
        <div className="PgFFC-SectionFour__textOne">
          <p>
            If you’re going to use the FBA program you’ll want to keep your fees
            as low as possible. The less you pay in fees the more profit you’ll
            have left over.
          </p>
        </div>
        <div className="PgFFC-SectionFour__tipsList">
          <div className="PgFFC__title PgFFC-SectionFour__mainTitle">
            Here are some tips to reduce your FBA fees:
          </div>
          <TipsList />
        </div>
        <div className="PgFFC-SectionFour__textTwo">
          <p>
            If you’re curious about what exactly your FBA fees will be, just
            check the item with the FBA Calculator widget. Products that are
            compact and lightweight will always have the lowest fees.
          </p>
        </div>
        <div className="PgFFC-SectionFour-questionsList">
          <div className="PgFFC__title PgFFC-SectionFour__mainTitle">
            Frequently Asked Questions
          </div>
          <Questions />
        </div>
      </div>
    </section>
  );
};

export default SectionFour;
