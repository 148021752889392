import React from "react";
import TrackedLink from "../../Analytics/TrackedLink";

const AnswerText = ({ beforeLink, link, afterLink }) => (
  <>
    {beforeLink && beforeLink}
    {link && (
      <>
        <TrackedLink
          category="FbaFeeCalculatorLP"
          action={link?.text}
          target
          path={link?.address}
          showMobileProPopup={link?.showMobileProPopup}
        >
          {link?.text}
        </TrackedLink>
        {afterLink && afterLink}
      </>
    )}
  </>
);
export default AnswerText;
