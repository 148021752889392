import SvgSectionOneOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_01.svg"
import SvgSectionOneTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_02.svg"
import SvgSectionOneThree from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_03.svg"
import SvgSectionOneFour from "../../assets/images/vector/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_04.svg"
import SvgSectionTwoOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_01.svg"
import SvgSectionTwoTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_02.svg"
import SvgSectionTwoThree from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_03.svg"
import SvgSectionTwoFour from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_04.svg"
import SvgSectionTwoFive from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_05.svg"
import SvgSectionTwoSix from "../../assets/images/vector/pages/fba-fee-calculator/SectionTwo/fbaFeeCalculator-s2_06.svg"
import SvgSectionFiveOne from "../../assets/images/vector/pages/fba-fee-calculator/SectionFive/fbaFeeCalculator-s5_01.svg"
import SvgSectionFiveTwo from "../../assets/images/vector/pages/fba-fee-calculator/SectionFive/fbaFeeCalculator-s5_02.svg"
import JpgSvgSectionOneOne from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01.jpg"
import JpgSvgSectionOneOne2x from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01@1_5x.jpg"
import WebpSvgSectionOneTwo from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01.webp"
import WebpSvgSectionOneTwo2x from "../../assets/images/raster/pages/fba-fee-calculator/SectionOne/fbaFeeCalculator-s1_yt01@1_5x.webp"

const PG_FFC_CONTENT = {
  SecOne: {
    youtubeImg: [
      JpgSvgSectionOneOne,
      JpgSvgSectionOneOne2x,
      WebpSvgSectionOneTwo,
      WebpSvgSectionOneTwo2x,
    ],
    listImg: [
      SvgSectionOneOne,
      SvgSectionOneTwo,
      SvgSectionOneThree,
      SvgSectionOneFour,
    ],
    list: [
      {
        beforeBoldText: null,
        boldText: "Install the FBA Calculator extension",
        afterBoldText: null,
      },
      {
        beforeBoldText: null,
        boldText: "Go to the listing page of the product you’d like to sell. ",
        afterBoldText:
          "Click the FBA Calculator icon in the top right corner of your browser to open the extension.",
      },
      {
        beforeBoldText: null,
        boldText: "Enter your product cost ",
        afterBoldText:
          "(the amount you pay to purchase it), shipping costs, CPC cost,and goods and services tax percentage",
      },
      {
        beforeBoldText: null,
        boldText: "The FBA Calculator will automatically calculate ",
        afterBoldText:
          "your Amazon fees, profit per unit, net margin, ROI, and estimated monthly financial earnings",
      },
    ],
  },
  SecTwo: {
    listImg: [
      SvgSectionTwoOne,
      SvgSectionTwoTwo,
      SvgSectionTwoThree,
      SvgSectionTwoFour,
      SvgSectionTwoFive,
      SvgSectionTwoSix,
    ],
    list: [
      [
        {
          beforeBoldText: null,
          boldText:
            "The FBA program saves you time and resources by allowing you to ship your inventory to Amazon. ",
          afterBoldText:
            "The company will store your items for you in one of their warehouses, and when someone buys one of your products they’ll pick, pack, and ship it.",
        },
      ],
      [
        {
          beforeBoldText: "Of course, this service doesn’t come free. ",
          boldText:
            "You’ll be charged a monthly storage fee while your items sit on Amazon’s shelves, ",
          afterBoldText:
            "and you’ll also be charged a fulfillment fee when they ship your orders. These fees vary in price, with smaller/lighter products costing less and larger/heavier items costing more.",
        },
        {
          beforeBoldText: null,
          boldText: "This is where the FBA estimator comes in handy. ",
          afterBoldText:
            "You can see exactly what your fees will be so you can decide if the cost is worth it.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "Overall, if you’re a new seller, or you’re a solo entrepreneur, then the FBA program is probably right for you. ",
          afterBoldText:
            "With this system, you don’t have to find space for all your inventory and you don’t have to worry about keeping up with orders. Plus, the FBA program means your products are eligible for Prime shipping, which is a great selling feature.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "If you don’t want to pay FBA fees then your other option is Fulfilled by Merchant (FBM). ",
          afterBoldText:
            "This means you’ll have to store your inventory and fulfill your orders yourself. So while there are fewer fees it’s a lot more work.",
        },
      ],
      [
        {
          beforeBoldText: null,
          boldText:
            "Generally, this option usually works best for larger businesses that have more than one employee and an established fulfillment system in place. ",
          afterBoldText:
            "For example, if you already sell on your own website and are used to fulfilling orders yourself, FBM could be a good fit for you.",
        },
      ],
      [
        {
          beforeBoldText: "Just remember that since ",
          boldText:
            "Amazon gets a lot of traffic your orders might increase once you start selling on their marketplace, ",
          afterBoldText: "so be prepared for that. ",
        },
      ],
    ],
  },
  SecFive: [SvgSectionFiveOne, SvgSectionFiveTwo],
};
export default PG_FFC_CONTENT;
