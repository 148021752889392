const PG_FFC_SECTION_FOUR_CONTENT = {
  tipsList: [
    {
      beforeBoldText: null,
      boldText: "Know the Different Pricing Tiers: ",
      afterBoldText:
        "As mentioned earlier, the fees vary depending on the size of your products. Storage fees are based on cubic feet while fulfillment fees are based on weight. Be sure to familiarize yourself with the different tiers and try to choose items that fall into the cheapest tiers.",
    },
    {
      beforeBoldText: null,
      boldText: "Small and Light Program: ",
      afterBoldText:
        "If your product is 16 x 9 x 4 inches or less and under 10 oz. you’ll qualify for the small and light program. This means you’ll be eligible for extra-low fulfillment fees. If your product is under 4 oz. you’ll pay the lowest possible fulfillment fee of just $1.97.",
    },
    {
      beforeBoldText: null,
      boldText: "Seller Fulfilled Prime: ",
      afterBoldText:
        "If your product is too heavy to sell through FBA, but you still want to qualify for Prime shipping, then you can apply for Seller Fulfilled Prime. This means you’ll fulfill your orders yourself while still offering Prime shipping to customers. Amazon has high standards for this program though, so you’ll have to meet their requirements.",
    },
  ],
  questionsList: [
    {
      question: "What’s the Difference Between FBA and FBM?",
      answerParagraphs: [
        {
          beforeLink:
            "The primary difference is that with FBA, Amazon stores your inventory and ships your orders for you. With FBM, you have to do that all yourself. FBA also charges additional fees for this service, whereas with FBM you don’t have to pay any storage or fulfillment fees. Keep in mind that with both options you’ll have to pay Amazon commission for every sale you make.",
          link: null,
          afterLink: null,
          list: null,
        },
      ],
    },
    {
      question: "How Do You Use the FBA Calculator?",
      answerParagraphs: [
        {
          beforeLink:
            "Want to know how to use the FBA Calculator? It’s super easy. Once the extension is installed, go to the product page of the item you want to sell and then open the FBA Profit Calculator. Enter your product and shipping costs (you can usually get these from your supplier) to estimate your fees and profit margins.",
          link: null,
          afterLink: null,
          list: null,
        },
      ],
    },
    {
      question: "What Are the Benefits of Using FBA?",
      answerParagraphs: [
        {
          beforeLink: "There are a number of benefits, such as:",
          link: null,
          afterLink: null,
          list: null,
        },
        {
          beforeLink: null,
          link: null,
          afterLink: null,
          list: [
            {
              beforeLink: "You don’t have to store your own inventory.",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink: "You don’t have to ship orders to customers.",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink: "You qualify for Prime shipping.",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink:
                "Because you don’t have to worry about fulfilling orders you can spend more time growing your business.",
              link: null,
              afterLink: null,
              list: null,
            },
          ],
        },
        {
          beforeLink:
            "Because of these advantages, FBA is ideal for small independent sellers.",
          link: null,
          afterLink: null,
          list: null,
        },
      ],
    },
    {
      question: "Does the FBA Calculator Work for All Marketplaces?",
      answerParagraphs: [
        {
          beforeLink:
            "Yes, you can use it in the USA, Canada, UK, Europe, Australia, and more.",
          link: null,
          afterLink: null,
          list: null,
        },
      ],
    },
    {
      question:
        "What Else Should You Check, in Addition to Fees and Sales Margins?",
      answerParagraphs: [
        {
          beforeLink: "When doing product research you should also check:",
          link: null,
          afterLink: null,
          list: null,
        },
        {
          beforeLink: null,
          link: null,
          afterLink: null,
          list: [
            {
              beforeLink: "Estimated monthly sales",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink: "How many reviews an item has",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink: "Sales and price trends",
              link: null,
              afterLink: null,
              list: null,
            },
            {
              beforeLink: "Competition levels",
              link: null,
              afterLink: null,
              list: null,
            },
          ],
        },
        {
          beforeLink:
            "Unfortunately, you can’t see this information in your Seller Central account, but it is available through the ",
          link: {
            address: `${process.env.SCOUT_EXT_PRO_NEW}?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp`,
            text: "AMZScout PRO AI Extension",
            showMobileProPopup: true,
          },
          afterLink: ".",
          list: null,
        },
      ],
    },
    {
      question: "How Do You Use the AMZScout PRO AI Extension?",
      answerParagraphs: [
        {
          beforeLink:
            "Follow these steps to use the PRO AI Extension to check the stats listed in the last section, plus other important information:",
          link: null,
          afterLink: null,
          list: null,
        },
        {
          beforeLink: null,
          link: null,
          afterLink: null,
          list: [
            {
              beforeLink: null,
              link: {
                address: `${process.env.SCOUT_EXT_PRO_NEW}?utm_source=site&utm_medium=amzscout&utm_campaign=fba_calc_lp`,
                text: "Install the PRO AI Extension",
                showMobileProPopup: true,
              },
              afterLink: null,
            },
            {
              beforeLink:
                "Go to Amazon and search for a product or niche you’re interested in",
              link: null,
              afterLink: null,
            },
            {
              beforeLink:
                "When the search results come up, click the PRO AI Extension icon in the top right corner of your browser to open it",
              link: null,
              afterLink: null,
            },
            {
              beforeLink:
                "See estimated monthly sales, reviews, sales trends, niche and product scores, and more",
              link: null,
              afterLink: null,
            },
          ],
        },
        {
          beforeLink:
            "A version of the FBA Calculator is also included within the PRO AI Extension, making this the ultimate product research tool and a great investment",
          link: null,
          afterLink: null,
          list: null,
        },
      ],
    },
  ],
};
export default PG_FFC_SECTION_FOUR_CONTENT;
